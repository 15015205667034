<script setup lang="ts">
import { useInternalLink } from '~/composables/useInternalLink'
import type { ThemeCollection } from '~/utils/types'

export type ThemeTeaser = ThemeCollection

const props = defineProps<{
	theme: ThemeTeaser
}>()

const el = ref()
const image = props.theme.header_image[0]
const link = useInternalLink(props.theme.uri)

const isInSidebar = ref(false)
onMounted(() => {
	if (el.value?.closest('aside')) isInSidebar.value = true
})
</script>

<template>
	<section
		class="theme-teaser"
		:class="{
			'theme-teaser--sidebar': isInSidebar,
		}"
		ref="el"
	>
		<div class="theme-teaser__text">
			<PillLabel color="white"> Thema </PillLabel>
			<h2
				class="text-title-mobile md:text-[55px] xl:text-title leading-none font-serif mb-4 mt-4 xl:mt-8"
			>
				<NuxtLink :to="link">
					{{ theme.title }}
				</NuxtLink>
			</h2>
			<div
				v-if="theme.description"
				class="mb-8 text-theme-text-mobile sm:text-theme-text"
				v-html="theme.description"
			></div>
			<Button type="nuxt" :to="link" :color="'green'">
				Ontdek het thema
			</Button>
		</div>

		<div class="image-container">
			<ThemeTeaserImage
				v-if="image"
				:image="image"
				:disableAnimation="isInSidebar"
			/>
		</div>
	</section>
</template>

<style scoped lang="postcss">
.theme-teaser {
	@apply relative z-0 w-full bg-nemo-blue-dark text-nemo-gray text-center overflow-hidden;
}

.theme-teaser__text {
	@apply relative z-20 pt-6 max-w-[680px] mx-auto px-8 box-content;
}

.image-container {
	@apply relative h-[370px] -mt-[70px] 2xl:h-[520px];
}
.theme-teaser--sidebar {
	.image-container {
		@apply h-[240px] sm:h-[370px];
	}
}
</style>
