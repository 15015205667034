<script setup lang="ts">
import { useMotionTransitions } from '@vueuse/motion'

const props = defineProps<{
	image: ImageAsset
	disableAnimation: boolean
}>()

const { y } = useWindowScroll()
const isMobile = useIsMobile()

const parent = useParentElement()
const bg = ref<HTMLElement | null>(null)

const { motionProperties } = useMotionProperties(bg)
motionProperties.x = '-50%'
motionProperties.y = '50%'
motionProperties.width = isMobile.value ? '100%' : '66%'
const { push } = useMotionTransitions()
const bounds = useElementBounding(parent)
const { height: windowHeight } = useWindowSize()

watch(y, () => {
	if (!parent.value || !bg.value || props.disableAnimation) return

	const { top, height } = bounds
	const endPosition = top.value + y.value // when top of image container goes out of view
	const startPosition = endPosition - windowHeight.value // when image container comes into view
	const { value: progress } = useProjection(
		y,
		[startPosition, endPosition],
		[0.5, 1],
	)

	push('width', progress * height.value * 2, motionProperties, {
		type: 'spring',
		damping: 10,
		stiffness: 100,
		mass: 0.01,
	})
})
</script>

<template>
	<div v-if="image" class="theme-teaser__background" ref="bg">
		<NuxtImg
			ref="imageEl"
			:alt="image.alt ?? image.altImported ?? 'Thema achtergrond beeld'"
			class="theme-teaser__background__image"
			:src="`uploads/${image.filename}`"
			fit="crop"
			:width="image.width"
			:height="image.height"
			sizes="100vw sm:100vw"
			densities="x1"
			:modifiers="{
				ar: 2,
				crop: 'focalpoint',
				'fp-x': image.focalPoint![0],
				'fp-y': image.focalPoint![1],
			}"
			loading="lazy"
		/>
	</div>
</template>

<style scoped lang="postcss">
.theme-teaser__background {
	@apply absolute z-10 bottom-0 left-[50%] w-[100%] sm:w-[40%] h-auto;
	@apply bg-black/10  aspect-square rounded-full overflow-hidden -translate-x-1/2 translate-y-1/2;
}

.theme-teaser__background__image {
	@apply w-full h-1/2 object-cover;
}
</style>
